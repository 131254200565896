var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('window-header'),_c('nav-form',{attrs:{"nuevo":false,"editar":false,"eliminar":false}}),_c('section',[_c('article',{staticStyle:{"height":"395px","width":"1200px"}},[_c('img',{staticStyle:{"position":"absolute","width":"40px","height":"40px","top":"4px","left":"6px"},attrs:{"src":_vm.formData.thumbnail && typeof _vm.formData.thumbnail == 'object'
            ? _vm.formData.thumbnail.imagen
            : require('./../assets/noimage.png')},on:{"dblclick":_vm.openPopupImagen}}),_c('field',{staticStyle:{"top":"5px","left":"68px"},attrs:{"name":"codigo","widget":"char","searchable":"","label":"Código","help":"Código del artículo","placeholder":"Cód. artículo","width":"100px","readonly":_vm.mode == 'edit',"inputStyle":"font-weight:bold;text-align:center;"}}),_c('field',{staticStyle:{"top":"5px","left":"180px"},attrs:{"name":"nombre","widget":"char","searchable":"","label":"Descripción","help":"Descripción del artículo","required":"","placeholder":"Descripción del artículo","width":"300px"}}),_c('field',{staticStyle:{"top":"5px","left":"490px"},attrs:{"name":"familia_id","searchable":"","widget":"m2o","dbAdapter":"familia","label":"Familia","placeholder":"Familia","width":"270px"}}),_c('field',{staticStyle:{"top":"5px","left":"770px"},attrs:{"name":"modelo_id","searchable":"","widget":"m2o","dbAdapter":"modelo","label":"Modelo","placeholder":"Modelo","width":"320px"}}),_c('r-tabs',{ref:"main-tabs",staticStyle:{"top":"40px","height":"355px"},attrs:{"wt":_vm.wt,"buttons":[
          'Movimientos',
          'F.Compras',
          'P.Ventas',
          'Cond. Ventas',
          'F.Ventas',
          'Depósitos',
          'Regularización',
          'Inventario' ]}},[_c('div',{staticClass:"tab"},[_c('field',{staticStyle:{"top":"2px","left":"2px","width":"1170px"},attrs:{"name":"movimientos","widget":"handsontable","height":309,"width":1170,"minRows":13,"readonly":"","htSettings":{ columnSorting: true },"columns":[
              { name: 'fecha', type: 'date2', header: 'Fecha' },
              {
                name: 'tipo',
                header: 'Tipo',
                type: 'select',
                options: {
                  factura_cliente: 'FV - Factura de Cliente',
                  albaran_cliente: 'AV - Albaran de Cliente',
                  condicional_cliente: 'CV - Condicional de Cliente',
                  factura_proveedor: 'FC - Factura de Proveedor',
                  albaran_proveedor: 'AC - Albaran de Proveedor',
                  condicional_proveedor: 'CC - Condicional de Proveedor',
                  deposito_representante: 'DR - Deposito de Representante',
                  regularizacion: 'RG - Regularización',
                },
              },
              { name: 'ndoc', header: 'Doc.' },
              {
                name: 'origen_id',
                header: 'Origen',
                type: 'm2o',
                primary: 'codigo',
                label: 'nombre',
                showCode: true,
              },
              {
                name: 'destino_id',
                header: 'Destino',
                type: 'm2o',
                primary: 'codigo',
                label: 'nombre',
                showCode: true,
              },
              { name: 'descripcion', header: 'Descripción' },
              {
                name: 'unidades',
                header: 'Unid.',
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
              },
              {
                name: 'peso',
                header: 'Peso',
                type: 'numeric',
                numericFormat: { pattern: '0,0.000' },
              },
              {
                name: 'descuentos',
                header: 'Dtos.',
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
                sufix: '%',
              },
              {
                name: 'precio',
                header: 'Precio',
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
              },
              {
                name: 'divisa_id',
                header: 'Divisa',
                type: 'm2o',
                primary: 'codigo',
                label: 'codigo',
              } ]}})],1),_c('div',{staticClass:"tab"},[_c('field',{staticStyle:{"top":"2px","left":"2px","width":"1170px"},attrs:{"name":"compras","widget":"handsontable","height":309,"width":1170,"minRows":13,"readonly":"","htSettings":{ columnSorting: true },"columns":[
              { name: 'fecha', type: 'date2', header: 'Fecha' },
              { name: 'ndoc', header: 'Doc.' },
              {
                name: 'origen_id',
                header: 'Proveedor',
                type: 'm2o',
                primary: 'codigo',
                label: 'nombre',
                showCode: true,
              },
              { name: 'descripcion', header: 'Descripción' },
              {
                name: 'unidades',
                header: 'Unid.',
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
              },
              {
                name: 'peso',
                header: 'Peso',
                type: 'numeric',
                numericFormat: { pattern: '0,0.000' },
              },
              {
                name: 'descuentos',
                header: 'Dtos.',
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
                sufix: '%',
              },
              {
                name: 'precio',
                header: 'Precio',
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
              },
              {
                name: 'divisa_id',
                header: 'Divisa',
                type: 'm2o',
                primary: 'codigo',
                label: 'codigo',
              } ]}})],1),_c('div',{staticClass:"tab"},[_c('field',{staticStyle:{"top":"2px","left":"2px","width":"1170px"},attrs:{"name":"pedidos","widget":"handsontable","height":309,"width":1170,"minRows":13,"readonly":"","htSettings":{ columnSorting: true },"columns":[
              { name: 'fecha', type: 'date2', header: 'Fecha' },
              { name: 'ndoc', header: 'Doc.' },
              {
                name: 'destino_id',
                header: 'Cliente',
                type: 'm2o',
                primary: 'codigo',
                label: 'nombre',
                showCode: true,
              },
              { name: 'descripcion', header: 'Descripción' },
              {
                name: 'unidades',
                header: 'Unid.',
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
              },
              {
                name: 'peso',
                header: 'Peso',
                type: 'numeric',
                numericFormat: { pattern: '0,0.000' },
              },
              {
                name: 'descuentos',
                header: 'Dtos.',
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
                sufix: '%',
              },
              {
                name: 'precio',
                header: 'Precio',
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
              },
              {
                name: 'divisa_id',
                header: 'Divisa',
                type: 'm2o',
                primary: 'codigo',
                label: 'codigo',
              } ]}})],1),_c('div',{staticClass:"tab"},[_c('field',{staticStyle:{"top":"2px","left":"2px","width":"1170px"},attrs:{"name":"condicionales","widget":"handsontable","height":309,"width":1170,"minRows":13,"readonly":"","htSettings":{ columnSorting: true },"columns":[
              { name: 'fecha', type: 'date2', header: 'Fecha' },
              { name: 'ndoc', header: 'Doc.' },
              {
                name: 'destino_id',
                header: 'Cliente',
                type: 'm2o',
                primary: 'codigo',
                label: 'nombre',
                showCode: true,
              },
              { name: 'descripcion', header: 'Descripción' },
              {
                name: 'unidades',
                header: 'Unid.',
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
              },
              {
                name: 'peso',
                header: 'Peso',
                type: 'numeric',
                numericFormat: { pattern: '0,0.000' },
              },
              {
                name: 'descuentos',
                header: 'Dtos.',
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
                sufix: '%',
              },
              {
                name: 'precio',
                header: 'Precio',
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
              },
              {
                name: 'divisa_id',
                header: 'Divisa',
                type: 'm2o',
                primary: 'codigo',
                label: 'codigo',
              } ]}})],1),_c('div',{staticClass:"tab"},[_c('field',{staticStyle:{"top":"2px","left":"2px","width":"1170px"},attrs:{"name":"facturas","widget":"handsontable","height":309,"width":1170,"minRows":13,"readonly":"","htSettings":{ columnSorting: true },"columns":[
              { name: 'fecha', type: 'date2', header: 'Fecha' },
              { name: 'ndoc', header: 'Doc.' },
              {
                name: 'destino_id',
                header: 'Cliente',
                type: 'm2o',
                primary: 'codigo',
                label: 'nombre',
                showCode: true,
              },
              { name: 'descripcion', header: 'Descripción' },
              {
                name: 'unidades',
                header: 'Unid.',
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
              },
              {
                name: 'peso',
                header: 'Peso',
                type: 'numeric',
                numericFormat: { pattern: '0,0.000' },
              },
              {
                name: 'descuentos',
                header: 'Dtos.',
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
                sufix: '%',
              },
              {
                name: 'precio',
                header: 'Precio',
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
              },
              {
                name: 'divisa_id',
                header: 'Divisa',
                type: 'm2o',
                primary: 'codigo',
                label: 'codigo',
              } ]}})],1),_c('div',{staticClass:"tab"},[_c('field',{staticStyle:{"top":"2px","left":"2px","width":"1170px"},attrs:{"name":"depositos","widget":"handsontable","height":309,"width":1170,"minRows":13,"readonly":"","htSettings":{ columnSorting: true },"columns":[
              { name: 'fecha', type: 'date2', header: 'Fecha' },
              { name: 'ndoc', header: 'Doc.' },
              {
                name: 'destino_id',
                header: 'Representante',
                type: 'm2o',
                primary: 'codigo',
                label: 'nombre',
                showCode: true,
              },
              { name: 'descripcion', header: 'Descripción' },
              {
                name: 'unidades',
                header: 'Unid.',
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
              },
              {
                name: 'peso',
                header: 'Peso',
                type: 'numeric',
                numericFormat: { pattern: '0,0.000' },
              },
              {
                name: 'descuentos',
                header: 'Dtos.',
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
                sufix: '%',
              },
              {
                name: 'precio',
                header: 'Precio',
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
              } ]}})],1),_c('div',{staticClass:"tab"},[_c('field',{staticStyle:{"top":"2px","left":"2px","width":"1170px"},attrs:{"name":"regularizaciones","widget":"handsontable","height":309,"width":1170,"minRows":13,"readonly":"","htSettings":{ columnSorting: true },"columns":[
              {
                name: 'fecha',
                type: 'date2',
                header: 'Fecha',
              },
              { name: 'ndoc', header: 'Doc.' },
              { name: 'descripcion', header: 'Descripción' },
              {
                name: 'destino_id',
                header: 'Almacén',
                type: 'm2o',
                primary: 'codigo',
                readOnly: true,
              },
              {
                name: 'unidades_real',
                header: 'Unid. reales',
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
              },
              {
                name: 'peso_real',
                header: 'Peso. reales',
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
              },
              {
                name: 'unidades_teorico',
                header: 'Unid. teórico',
                readOnly: true,
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
              },
              {
                name: 'peso_teorico',
                header: 'Peso teórico',
                readOnly: true,
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
              },
              {
                name: 'unidades_dif',
                header: 'Unid. discrep.',
                readOnly: true,
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
              },
              {
                name: 'peso_dif',
                header: 'Peso discrep.',
                readOnly: true,
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
              } ]}})],1),_c('div',{staticClass:"tab"},[_c('field',{staticStyle:{"top":"2px","left":"2px","width":"1170px"},attrs:{"name":"inventario2","widget":"handsontable","height":309,"width":1170,"minRows":13,"readonly":"","htSettings":{ columnSorting: true },"columns":[
              {
                name: 'almacen_id',
                header: 'Almacen',
                type: 'm2o',
                primary: 'codigo',
                label: 'nombre',
                showCode: true,
              },
              {
                name: 'unidad',
                header: 'Unidad',
                type: 'numeric',
                numericFormat: { pattern: '0,0.000' },
              },
              {
                name: 'peso',
                header: 'Peso',
                type: 'numeric',
                numericFormat: { pattern: '0,0.000' },
              } ],"fields":['wt']}})],1)])],1)]),_c('v-collapse-wrapper',{ref:"main-collapse",attrs:{"active":true}},[_c('div',{directives:[{name:"collapse-toggle",rawName:"v-collapse-toggle"}],staticClass:"header"},[_vm._v(" Listado "),_c('div',{staticClass:"item-after"},[_vm._v(_vm._s(_vm.count))])]),_c('div',{directives:[{name:"collapse-content",rawName:"v-collapse-content"}],staticClass:"my-content"},[_c('hot-table',{ref:"hotTableComponent",attrs:{"settings":_vm.htSettings}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }